export const config = {
    COIN_DECIMALS: 6,
    PREFIX: 'osmo',
    CONTRACT_ADDRESS: 'osmo17kqws7fy2qnejdw68cqjlw456gdxcdukk0wq6026u826zlda3y7qdhzqlv',
    DENOM: 'OSMO',
};

export const DEV_URL = 'https://data-api.streamswap.io';
export const STREAMS = [8, 9, 10];
export const STREAM_8 = 'LAB';
export const STREAM_9 = 'TNKR';
export const STREAM_10 = 'HAVA';
export const DEFAULT_SKIP = 0;
export const DEFAULT_LIMIT = 10;
export const DEFAULT_SORT_BY = 'total';
export const DEFAULT_ORDER = 'desc';
