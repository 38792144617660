import React from 'react';
import './index.css';
import variables from '../../utils/variables';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchLeaderboardList, setFilter } from '../../actions/home';
import Table from './Table';
import { DEFAULT_LIMIT, DEFAULT_ORDER, DEFAULT_SKIP, DEFAULT_SORT_BY } from '../../config';

class Home extends React.Component {
    componentDidMount () {
        this.props.fetchLeaderboardList(DEFAULT_SKIP, DEFAULT_LIMIT, DEFAULT_SORT_BY, DEFAULT_ORDER, '');
    }

    render () {
        return (
            <div className="home">
                <div className="header">
                    <h2>{variables[this.props.lang]['stream_swap_test_net']}</h2>
                    {/* <Button onClick={() => this.props.setFilter(!this.props.filter)}> */}
                    {/*    Filter */}
                    {/* </Button> */}
                </div>
                {/* {this.props.filter */}
                {/*    ? <div className="filter_section"> */}
                {/*        <AddressTextField /> */}
                {/*    </div> */}
                {/*    : null} */}
                <div className="table_section">
                    <Table />
                </div>
            </div>
        );
    }
}

Home.propTypes = {
    fetchLeaderboardList: PropTypes.func.isRequired,
    filter: PropTypes.bool.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    setFilter: PropTypes.func.isRequired,
    count: PropTypes.number,
    limit: PropTypes.number,
    skip: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        lang: state.language,

        list: state.home.leaderboardList.list,
        inProgress: state.home.leaderboardList.inProgress,
        limit: state.home.leaderboardList.limit,
        skip: state.home.leaderboardList.skip,
        count: state.home.leaderboardList.count,

        filter: state.home.filter.open,
    };
};

const actionToProps = {
    fetchLeaderboardList,
    setFilter,
};

export default connect(stateToProps, actionToProps)(Home);
