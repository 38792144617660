import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import TextField from '../../components/TextField';
import { decodeFromBech32 } from '../../utils/address';
import { fetchLeaderboardList, setAddress } from '../../actions/home';
import { config } from '../../config';

class AddressTextField extends React.Component {
    constructor (props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange (value) {
        const valid = value && decodeFromBech32(value) && (value.indexOf(config.PREFIX) > -1);
        this.props.onChange(value, valid);
        if (value !== '' && valid) {
            this.props.fetchLeaderboardList(this.props.skip, this.props.limit, this.props.sortBy, this.props.order, value);
        } else if (value === '') {
            this.props.fetchLeaderboardList(this.props.skip, this.props.limit, this.props.sortBy, this.props.order);
        }
    }

    render () {
        return (
            <TextField
                className={this.props.valid ? '' : 'error_text_field'}
                error={this.props.value !== '' ? !this.props.valid : false}
                errorText="Please Enter valid Address"
                id="address_text_field"
                name="address"
                placeholder="Enter Address"
                valid={this.props.valid}
                value={this.props.value}
                variant="filled"
                onChange={this.onChange}/>
        );
    }
}

AddressTextField.propTypes = {
    fetchLeaderboardList: PropTypes.func.isRequired,
    sortBy: PropTypes.string.isRequired,
    valid: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    limit: PropTypes.number,
    order: PropTypes.string,
    skip: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        value: state.home.accountAddress.value,
        valid: state.home.accountAddress.valid,

        skip: state.home.leaderboardList.skip,
        limit: state.home.leaderboardList.limit,
        sortBy: state.home.leaderboardList.sortBy,
        order: state.home.leaderboardList.order,
    };
};

const actionsToProps = {
    onChange: setAddress,
    fetchLeaderboardList,
};

export default connect(stateToProps, actionsToProps)(AddressTextField);
